import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {useEffect} from 'react';
import reactPackage from 'react/package.json';

import {BUGSNAG_API_KEY} from '~/utils/constants';

interface startBugsnagProps {
  nodeEnv: string;
  appEnv: string;
  revision: string;
}

interface useBugsnagProps {
  nodeEnv: string;
  appEnv: string;
  userId: number;
  revision: string;
  shop: {
    id: string;
    name: string;
    url: string;
  };
}

interface ErrorConfig {
  name: string | undefined;
  messages: string[];
}

const REACT_IGNORABLE_ERRORS: ErrorConfig = {
  name: undefined,
  messages: [
    // 418: https://reactjs.org/docs/error-decoder.html?invariant=418
    'Minified React error #418',
    // 423: https://react.dev/errors/423?invariant=423
    'Minified React error #423',
    // 425: https://reactjs.org/docs/error-decoder.html?invariant=425
    'Minified React error #425',
  ],
};

const ABORT_ERROR_CONFIG: ErrorConfig = {
  name: 'AbortError',
  messages: [
    'signal is aborted without reason',
    'The operation was aborted.',
    'Request signal is aborted',
  ],
};

const GENERIC_ERROR_CONFIG: ErrorConfig = {
  name: undefined,
  messages: [
    'Unexpected Server Error',
    // These comes from monorail and is not really actionable by our app
    'Error: Retry count of 10 exceeded.',
    'Error producing to the Monorail Edge.',
    // This isn't an actionable error by our app
    '401 error:',
  ],
};

export function startBugsnag({nodeEnv, revision, appEnv}: startBugsnagProps) {
  if (!Bugsnag.isStarted()) {
    Bugsnag.start({
      // If changed, update also in upload-sourcemaps.ts
      apiKey: BUGSNAG_API_KEY,
      appVersion: revision,
      plugins: [new BugsnagPluginReact()],
      releaseStage: appEnv,
      collectUserIp: false,
      onError: (event) => {
        // eslint-disable-next-line no-console
        console.error(event);

        // this logic was suggested by Cal Irvine and blessed by a Remix dev (Matt Brophy): https://github.com/Shopify/shop-importer-app/blob/3a398c636fbcb90474ec63b4b6d55bcc3cdeba53/app/utilities/bugsnag/common.ts#L33-L36
        // Note: this doesn't seem to work yet, but I suspect it's related to https://github.com/remix-run/remix/issues/10005
        if (event.errors.some((error) => !error.stacktrace.length)) {
          return false;
        }

        const originalError = event.originalError;

        const shouldIgnoreError = (errorConfig: ErrorConfig) => {
          if (!errorConfig.name || originalError.name === errorConfig.name) {
            return errorConfig.messages.some((message) =>
              originalError.message.startsWith(message),
            );
          }
          return false;
        };

        const shouldIgnore = [ABORT_ERROR_CONFIG, GENERIC_ERROR_CONFIG].some(
          shouldIgnoreError,
        );

        if (shouldIgnore) {
          return false;
        }

        // Ignore minified React errors until we update to React 19 which gives much better error messages
        // For now, we don't get enough information on the error to be able to track it, but React 19 adds a lot more context: https://react.dev/blog/2024/12/05/react-19#diffs-for-hydration-errors
        const [reactMajorVersion] = reactPackage.version.split('.');
        if (
          Number(reactMajorVersion) < 19 &&
          shouldIgnoreError(REACT_IGNORABLE_ERRORS)
        ) {
          return false;
        }

        // Disable bugsnag for development
        return nodeEnv === 'production';
      },

      featureFlags: [{name: 'Remix', variant: 'true'}],
    });
  }
}

export function useBugsnag({
  nodeEnv,
  userId,
  shop,
  revision,
  appEnv,
}: useBugsnagProps) {
  startBugsnag({
    nodeEnv,
    revision,
    appEnv,
  });

  useEffect(() => {
    Bugsnag.addMetadata('Shop', {
      id: shop.id,
      name: shop.name,
      domain: shop.url,
    });

    Bugsnag.setUser(userId.toString());

    return () => {
      Bugsnag.clearMetadata('Shop');
    };
  }, [shop, userId]);
}
