import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import {InMemoryCache, defaultDataIdFromObject} from '@apollo/client/cache';
import {onError} from '@apollo/client/link/error';
import {parseGid} from '@shopify/admin-graphql-api-utilities';
import {
  Provider as AppBridgeProvider,
  Loading,
} from '@shopify/app-bridge-react';
import {AppProvider, Frame} from '@shopify/polaris-internal';
import translations from '@shopify/polaris-internal/locales/en.json';
import {ReportifyClient, ReportifyProvider} from '@shopify/reportify-react';
import {
  type CurrencyCode,
  I18nContext,
  I18nManager,
  useI18n,
} from '@shopify-internal/react-i18n';
import React, {useMemo, useState} from 'react';

import {useAuthenticatedFetch, useSessionStorage} from '~/hooks';
import {type AppData} from '~/types/shared';
import {isErrorExpected} from '~/utils/expectedErrors';

import {ConfirmLeaveModalContextProvider} from '../ConfirmLeaveModalContext';
import {SessionIdContextProvider} from '../SessionIdContext';

import {RenderChildrenOrThrow, SmartLink} from './components';
import {SharedDataContext, type SharedDataContextType, notify} from './context';
import {AnalyticsTokenDocument} from './graphql/AnalyticsTokenQuery.core.graphql.generated';
import {useBugsnag, useEnableMetaDefinitions} from './hooks';

import type {SerializeFrom} from '@remix-run/node';
import type {loader} from '~app/root';
import type {OptionalChain} from '~app/ui/types';

import {isProduction} from '~app/ui/utils/env';

interface AppSetupContextProps {
  data: AppData;
  sharedData: NonNullable<
    OptionalChain<SerializeFrom<typeof loader>, ['sharedData', 'data']>
  >;
  children: React.ReactNode;
  /** The React node to show while loading the initialization network request */
  loadingFallback?: React.ReactNode;
}

/**
 * The shared context between our ModalApp and App. Contains all of the context that should be in both.
 * We need to wrap the Inner component with this one so that it has access to useAppBridge
 */
export function AppSetupContext({
  data,
  sharedData,
  children,
  loadingFallback,
}: AppSetupContextProps) {
  const [sharedDataContextValue] = useState(() => {
    if (!sharedData.app || !sharedData.staffMember) {
      throw new Error(
        `sharedData is missing required fields. app: ${JSON.stringify(sharedData.app)}, staffMember: ${JSON.stringify(sharedData.staffMember)}`,
      );
    }

    /*
     * non-unified-admin example:
     * domain: "shop1.shopify.unified-admin.jason-addleman.us.spin.dev"
     * host:   "shop1.shopify.unified-admin.jason-addleman.us.spin.dev/admin"
     *
     * unified-admin example:
     * domain: "shop1.shopify.unified-admin.jason-addleman.us.spin.dev"
     * host:   "admin.web.unified-admin.jason-addleman.us.spin.dev/store/shop1"
     */
    const host = atob(data.host);
    const unifiedAdminEnabled = !host.startsWith(
      sharedData.shop.myshopifyDomain,
    );

    return {
      ...sharedData,
      app: {
        ...sharedData.app,
        appStoreAppUrl:
          sharedData.app.appStoreAppUrl ||
          'https://apps.shopify.com/search-and-discovery',
        handle: sharedData.app.handle || 'search-and-discovery',
      },
      staffMember: {
        id: Number(parseGid(sharedData.staffMember.id)),
        locale: sharedData.staffMember.locale,
        adminPermissions: sharedData.staffMember.permissions.userPermissions,
      },
      unifiedAdminEnabled,
    };
  });

  const [preferredLocale] = useSessionStorage('preferredLocale', '');
  const [preferredCurrencyCode] = useSessionStorage(
    'preferredCurrencyCode',
    '' as CurrencyCode,
  );

  const locale = preferredLocale || sharedDataContextValue.staffMember.locale;
  const currency =
    preferredCurrencyCode || sharedDataContextValue.shop.currencyCode;

  const i18nManager = useMemo(() => {
    return new I18nManager({
      locale,
      currency,
      // a (hopefully) temporary fix for https://github.com/Shopify/quilt/issues/1685
      onError(error) {
        if (isProduction) {
          // eslint-disable-next-line no-console
          console.error(error);
        } else {
          throw error;
        }
      },
    });
  }, [currency, locale]);

  return (
    <AppBridgeProvider
      config={{
        apiKey: data.apiKey,
        forceRedirect: true,
        host: data.host,
      }}
    >
      <SharedDataContext.Provider value={sharedDataContextValue}>
        <I18nContext.Provider value={i18nManager}>
          <Inner
            data={data}
            sharedData={sharedDataContextValue}
            loadingFallback={loadingFallback}
          >
            {children}
          </Inner>
        </I18nContext.Provider>
      </SharedDataContext.Provider>
    </AppBridgeProvider>
  );
}

interface InnerProps {
  data: AppData;
  sharedData: SharedDataContextType;
  children: React.ReactNode;
  /** The React node to show while loading the network request */
  loadingFallback?: React.ReactNode;
}
function Inner({children, data, sharedData, loadingFallback}: InnerProps) {
  const authenticatedFetch = useAuthenticatedFetch();

  const apolloClient = useMemo(() => {
    // The query params are added for logging purposes, they have no effect in the BE
    const enrichUrlLink = new ApolloLink((operation, forward) => {
      const operationType = (() => {
        const source = operation.query.loc?.source?.body;
        if (source) {
          const typeRegex = /(query|mutation|subscription)\s/;
          const matches = typeRegex.exec(source);

          return matches?.[1];
        }
      })();
      let uri = `/graphql/proxy?type=${operationType}&operation=${operation.operationName}`;

      if (!operation.getContext().skipLocale) {
        uri += `&locale=${sharedData.staffMember.locale ?? 'en'}`;
      }

      operation.setContext({
        uri,
      });
      return forward(operation);
    });

    const errorLink = onError(
      ({graphQLErrors, operation, forward, networkError}) => {
        const isExpected = isErrorExpected(
          new ApolloError({graphQLErrors, networkError}),
        );
        if (graphQLErrors && !isExpected) {
          graphQLErrors.forEach(({message}) => {
            const error = new Error(message);
            notify({
              errorClass: 'GraphQLError',
              stack: error.stack,
              errorMessage: message,
            });
          });
        }

        return forward(operation);
      },
    );

    const link = from([
      enrichUrlLink,
      errorLink,
      createHttpLink({
        credentials: 'include',
        fetch: authenticatedFetch,
      }),
    ]);

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        dataIdFromObject: (object) => {
          // We need to use the cache key for the OnlineStore object, otherwise creating new filters (maybe specifically metafield filters) can cause infinite revalidation loops in StrictMode with notifyOnNetworkStatusChange
          if (object.__typename === 'OnlineStore') {
            return `${object.__typename}:cache`;
          }
          return defaultDataIdFromObject(object);
        },
      }),
    });

    return client;
  }, [authenticatedFetch, sharedData.staffMember.locale]);

  const reportifyClient = useMemo(
    () =>
      new ReportifyClient({
        source: 'shopify-seach-and-discovery',
        refetchToken: async () => {
          const {data} = await apolloClient.query({
            query: AnalyticsTokenDocument,
            fetchPolicy: 'network-only',
          });

          return data?.shop?.authorizedAnalyticsToken || '';
        },
      }),
    [apolloClient],
  );

  const {enablingDefinitions, error: enablingDefinitionsError} =
    useEnableMetaDefinitions({
      client: apolloClient,
    });

  useBugsnag({
    nodeEnv: data.nodeEnv,
    appEnv: data.appEnv,
    shop: sharedData.shop,
    userId: sharedData.staffMember.id,
    revision: data.revision,
  });

  const [i18n] = useI18n({
    id: 'Polaris',
    fallback: translations,
    translations(locale) {
      // Note: this needs to be a relative import with the way vite works: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
      return import(
        `../../../../../node_modules/@shopify/polaris-internal/locales/${locale}.json`
      );
    },
  });

  const error = enablingDefinitionsError;
  const anyLoading = enablingDefinitions;

  if (anyLoading) {
    return loadingFallback ? <>{loadingFallback}</> : <Loading />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AppProvider i18n={i18n.translations} linkComponent={SmartLink}>
        {/* The Frame is mandatory as a wrapper for reportify */}
        <Frame>
          <RenderChildrenOrThrow error={error}>
            <ReportifyProvider client={reportifyClient}>
              <ConfirmLeaveModalContextProvider>
                <SessionIdContextProvider>{children}</SessionIdContextProvider>
              </ConfirmLeaveModalContextProvider>
            </ReportifyProvider>
          </RenderChildrenOrThrow>
        </Frame>
      </AppProvider>
    </ApolloProvider>
  );
}
